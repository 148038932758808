import React, { useEffect, useRef } from "react";
import "../styles/feedback.scss";

//         text: "Liebe Joanna, einen ganz lieben und herzlichen Dank für deine wundervolle Arbeit. Auf deiner Liege zu liegen fühlt sich nach kurzer Zeit schon so an als würde man den Kontakt auf der Liege fühlen und gleichzeitig schweben. Dieses Los lassen das sich wie eine Befreiung anfühlt, macht glaube ich dieses Gefühl zu schweben. Zu fühlen welche Last, Ballast gerade weg geht ist ein leicht werden im ganzen System. Ich freue mich schon jetzt auf den nächsten Termin.",
//         author: "Joachim"

//     //     text: "Om Suastiastu Joanna. Ohne Sprache, einfach nur fühlen dürfen. Was von Herzen kommt, benötigt keine Worte ❤.",
//     //     author: "Marco"

const Feedback = () => {
  const widgetRef = useRef(null);

  const widgetId = "c641672d-a761-42bf-ba79-370367b20049";
  useEffect(() => {
    const loadScript = () => {
      const script = document.createElement("script");
      script.src = "https://featurable.com/assets/bundle.js";
      script.defer = true;
      script.charset = "UTF-8";
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    };

    loadScript();

    if (widgetRef.current) {
      const div = widgetRef.current;
      div.setAttribute("id", `featurable-${widgetId}`);
      div.setAttribute("data-featurable-async", "");
      div.setAttribute("data-location-code", "de");
    }
  }, [widgetId]);

  return (
    <div style={{ maxHeight: "800px" }} className=" section kundenfeedback">
      <h2>Feedback</h2>
      <div ref={widgetRef}></div>
    </div>
  );
};

export default Feedback;
